import Project1 from '../assets/project1.jpeg';
import Project2 from '../assets/project2.jpeg';
import Project3 from '../assets/project3.png';
import Project4 from '../assets/project4.png';
import Project5 from '../assets/project5.png';
import Project6 from '../assets/project6.png';

export const ProjectList = [
  {
    name: 'E-commerce clothing store',
    image: Project1,
    skills:
      'HTML, SASS/SCSS, Styled Components, JavaScript, React, Firebase, CRUD Operations.',
    link: 'https://github.com/Yann981/ecommerce',
  },
  {
    name: 'JavaScript Calculator',
    image: Project2,
    skills: 'HTML, CSS, JavaScript.',
    link: 'https://github.com/Yann981/jscalculator',
  },
  {
    name: 'DIY landing page',
    image: Project3,
    skills: 'HTML, CSS, JavaScript.',
    link: 'https://github.com/Yann981/diy-landing-page',
  },
  {
    name: 'Music Player',
    image: Project4,
    skills: 'HTML, CSS, JavaScript.',
    link: 'https://github.com/Yann981/musicplayer',
  },
  {
    name: 'Infinite Scroll - Unsplash API',
    image: Project5,
    skills: 'HTML, CSS, JavaScript.',
    link: 'https://github.com/Yann981/InfiniteScroll',
  },
  {
    name: 'Random Inspriational Quote Generator',
    image: Project6,
    skills: 'HTML, CSS, JavaScript.',
    link: 'https://github.com/Yann981/RandomQuoteGenerator',
  },
];
